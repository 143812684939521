import React, {useEffect, useState} from 'react';
import Link from "next/link";
import ChangeLanguage from "./changeLanguage";
import MainButton from "../mainButton";
import {useRouter} from "next/router";

let scrollTop;
let prevDepth;


const Header = ({t, hash}) => {
    const router = useRouter();

    const nav = t('common:menu', {}, {returnObjects: true})
    const additional_menu = t('common:additional_menu', {}, {returnObjects: true})

    const [scrolled, setScrolled] = React.useState(false);
    const [blur, setBlur] = React.useState(false);
    const [activeLink, setActiveLink] = React.useState('#');
    const locales = t('common:locales', {}, {returnObjects: true})

    const [origin, setOrigin] = useState('')

    useEffect(() => {
        setOrigin(window.location.origin)
    }, [])

    const handleScroll = () => {

        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        if (scrollTop < 10) {
            setBlur(false)
        }

        if (scrollTop >= 11) {
            if (prevDepth < scrollTop) {
                setScrolled(true)

            } else {
                setScrolled(false)
                setBlur(true)
            }
        }
        prevDepth = window.pageYOffset || document.documentElement.scrollTop;
    }

    const toggleMenu = () => {
        let nav = document.getElementById('main_nav');
        nav.classList.toggle('visible');
        if (nav.classList.contains('visible')) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }
    }

    useEffect(() => {
        setScrolled(false)
        setTimeout(() => {
            window.addEventListener('scroll', handleScroll)
        }, 100)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    useEffect(() => {
        if (!router.asPath.includes('#')) {
            setActiveLink('#')
        } else {
            setActiveLink(router.asPath.split('#')[1])
        }

    }, [router])

    return (
        <header
            className={`header ${scrolled ? ' scrolled' : ''} ${blur ? 'blur' : ''}`}>
            <div className="container">
                <div className="header_content">
                    <div className="logo">
                        <Link href={'/'}>
                            <img
                                src={'/img/main/main_logo.svg'}
                                height='20'
                                width='145'
                                className="logo-lg"
                                alt="PARLINE Logo"
                            />
                        </Link>
                    </div>
                    <nav className='header_nav'>
                        <ul id='main_nav' className='main_nav'>
                            {nav.map((item, i) => {
                                return <li
                                    className={
                                        activeLink === item.slug.split('#')[1] ? 'main_nav_item is_link_active' : 'main_nav_item'
                                    }
                                    key={item.link + new Date().getTime()}
                                >
                                    <Link
                                        target={item.target}
                                        scroll={false}
                                        prefetch={false}
                                        href={
                                            item.slug === 'audit' ? 'https://www.cyberscope.io/audits/1-prln' :
                                                origin + item.slug
                                        }
                                        className={
                                            activeLink === item.slug.split('#')[1] ? 'link is_link_active' : 'link'
                                        }
                                        id={item.id}
                                        onClick={() => {
                                            // scrollToAnchor(item.slug)
                                            window.innerWidth < 992 && toggleMenu()
                                        }}> {item.link}

                                    </Link>
                                </li>
                            })}
                            <li onClick={() => {
                                toggleMenu()
                            }} className='image_close'>
                                <img
                                    className=""
                                    src={"/img/icons/close.svg"}
                                    alt="PARLINE Logo"
                                    width={18}
                                    height={18}
                                />
                            </li>
                            <li className={'mob_hide'}>
                                <Link
                                    prefetch={false}
                                    href={process.env.NEXT_PUBLIC_BUY_TOKEN_APP_URL}
                                >
                                    <MainButton
                                        text={additional_menu?.link}
                                    />
                                </Link>
                            </li>
                        </ul>
                        <ul className='main_nav_additional'>

                            <li className='flags '>
                                <ChangeLanguage
                                    locales={locales}
                                />
                            </li>
                            <li onClick={() => {
                                toggleMenu()
                            }} className='image_open'>

                                <img
                                    width='16'
                                    height='12'
                                    // loading="lazy"
                                    className=""
                                    src={"/img/icons/open.svg"}
                                    alt="PARLINE Logo"
                                />
                            </li>
                        </ul>
                        <Link
                            className='mob_show'
                            prefetch={false}
                            href={process.env.NEXT_PUBLIC_BUY_TOKEN_APP_URL}
                        >
                            <MainButton
                                log_in
                                text={additional_menu?.link}
                            />
                        </Link>
                    </nav>
                </div>
            </div>
        </header>
    );
}

export default Header;
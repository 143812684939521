import React from 'react';
import Link from "next/link";

const Footer = ({t, full}) => {

        const footer = t('common:footer', {}, {returnObjects: true});

        return (
            <footer className='footer_section'>
                {full ? <div className='footer_section_top'>
                    <div className="container">
                        <div className='footer_section_top_wrapper'>
                            <div className='main_nav'>
                                <div className="logo">
                                    <Link href={'/'}>
                                        <img
                                            src={'/img/main/main_logo.svg'}
                                            height='20'
                                            width='145'
                                            className="logo-lg"
                                            alt="PARLINE Logo"
                                        />
                                    </Link>
                                </div>
                                <nav className="nav">
                                    <ul className='nav_list'>
                                        {footer.nav.list.map(item => {
                                            return <li
                                                className='social_item'
                                                key={item.link}
                                            >
                                                <Link prefetch={false}
                                                      href={
                                                          item.url === 'whitepaper' ? '/' :
                                                              item.url
                                                      }
                                                >
                                                    {item.link}
                                                </Link>
                                            </li>
                                        })}
                                    </ul>
                                </nav>
                            </div>
                            <div className="social">
                                <h2>{footer.social.title}</h2>
                                <ul className="social_list">
                                    {footer.social.list.map(item => {
                                        return <li
                                            className='social_item'
                                            key={item.link}
                                        >
                                            <Link
                                                prefetch={false}
                                                href={item.url}
                                                aria-label={item.link}
                                            >
                                                <svg
                                                    fillRule="evenodd" clipRule="evenodd"
                                                    width={item.icon.w} height={item.icon.h}
                                                    viewBox={`0 0 ${item.icon.w} ${item.icon.h}`}
                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d={item.icon.d} fill="white"/>
                                                </svg>
                                            </Link>
                                        </li>
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> : null}
                <div className='footer_section_bottom'>
                    <div className="container">
                        <div className="footer_section_bottom_wrapper">
                            <div className='copyright'>
                                {footer.copywright_1} {new Date().getFullYear()} {footer.copywright_2}
                            </div>
                            <ul className='links'>
                                <li>
                                    <Link prefetch={false}
                                          href={'/terms-of-use'}
                                    >
                                        {footer.terms}
                                    </Link>
                                </li>
                                <li>
                                    <Link prefetch={false}
                                          href={'/privacy-policy'}
                                    >
                                        {footer.privacy}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
;

export default Footer;
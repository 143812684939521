import React from 'react';

const Loader = ({isLoading, loaded}) => {
    return (
        <div className={`page_loader ${isLoading ? 'loading' : ''} ${loaded ? 'loaded' : ''}`}>
            <div className="page_loader_content">
                <img
                    width={26}
                    height={31}
                    src='/img/main/logo_part.svg'
                    alt='PARLINE rotate image'/>

                <svg viewBox="0 0 1320 158">
                    <text x="50%" y="50%" dy=".35em" textAnchor="middle">
                        PARLINE
                    </text>
                </svg>

            </div>
        </div>
    );
};

export default Loader;
import React from 'react';

const MainButton = (props) => {

    const {text, outline, log_in} = props;

    return (
        <button
            className={`main_button ${outline ? 'outline' : ''} ${log_in ? 'log_in' : ''}`}
        >
            {text}
        </button>
    );
};

export default MainButton;